import { useEffect } from 'react';
import AOS from 'aos';
import logo from './assets/logo.png';
import './App.sass';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000
    });
    AOS.refresh();
  });

  return (
    <div className="app">
      <header className="header">
        <a href="https://nyan.pl/">
          <img src={logo} alt="logo" className='header-image' />
        </a>
        <a href="https://discord.gg/9s4mYFfruR">
          <div className="btn">Support</div>
        </a>
      </header>
      <div className="hero">
        <div className="hero-section" data-aos="fade-right">
          <h1 className="hero-title">Profesjonalny BOT <br /> na twój serwer Discord</h1>
          <div className="hero-buttons">
            <a href="https://discord.com/oauth2/authorize?client_id=929716739149803540&scope=bot&permissions=2146958847">
              <div className="btn hero-btn">Dodaj Nyan</div>
            </a>
            <a href="https://discord.com/oauth2/authorize?client_id=938058944390242324&permissions=8&scope=bot">
              <div className="btn hero-btn">Dodaj Music</div>
            </a>
          </div>
        </div>
        <div className="hero-section" data-aos="fade-left">
          <img src={logo} alt="logo" className='hero-image' />
        </div>
      </div>
      <div className="benefits" data-aos="fade-up">
        <h1 className="benefits-title">Zalety naszego bota</h1>
        <ul className="benefits-list">
          <li className="benefits-wrapper">
            <h1 className="benefits-title">Wydajność</h1>
            <p className="benefits-content">Dbamy o wydajność bota, aby działał bezproblemowo.</p>
          </li>
          <li className="benefits-wrapper">
            <h1 className="benefits-title">Bezpieczeństwo</h1>
            <p className="benefits-content">Token bota jest bezpiecznie przechowywany, dzięki czemu bot jest bezpieczny.</p>
          </li>
          <li className="benefits-wrapper">
            <h1 className="benefits-title">Aktywność</h1>
            <p className="benefits-content">Nasze boty są aktywne 24/7!</p>
          </li>
        </ul>
      </div>
      <footer className="footer">
        <p className='footer-text-primary'>Copyright &copy; Nyan.pl 2022</p>
        <p className='footer-text-secondary'>Realizacja <a href="https://allmake.pl/">allMake.pl</a></p>
      </footer>
    </div>
  )
}

export default App;
